/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import "typeface-roboto"

// Redux provider
import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider
