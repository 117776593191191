// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-basic-tpl-js": () => import("./../../../src/templates/BasicTpl.js" /* webpackChunkName: "component---src-templates-basic-tpl-js" */),
  "component---src-templates-events-tpl-js": () => import("./../../../src/templates/EventsTpl.js" /* webpackChunkName: "component---src-templates-events-tpl-js" */),
  "component---src-templates-gallery-tpl-js": () => import("./../../../src/templates/GalleryTpl.js" /* webpackChunkName: "component---src-templates-gallery-tpl-js" */),
  "component---src-templates-jobs-tpl-js": () => import("./../../../src/templates/JobsTpl.js" /* webpackChunkName: "component---src-templates-jobs-tpl-js" */),
  "component---src-templates-news-tpl-js": () => import("./../../../src/templates/NewsTpl.js" /* webpackChunkName: "component---src-templates-news-tpl-js" */),
  "component---src-templates-page-tpl-js": () => import("./../../../src/templates/PageTpl.js" /* webpackChunkName: "component---src-templates-page-tpl-js" */),
  "component---src-templates-user-highlight-tpl-js": () => import("./../../../src/templates/UserHighlightTPL.js" /* webpackChunkName: "component---src-templates-user-highlight-tpl-js" */)
}

