export default (state, action) => {
  switch (action.type) {
    case "hgr0221":
      return {
        st: action.st,
        content: action.payload,
      }

    default:
      return state
  }
}
